import React, { useState } from "react";
import { FormProvider } from "../../components/FormContext";
import WWWContainer from "../components/WWWContainer";
import WWWForm from "./WWWForm";

const Index = () => {
  const [page, setPage] = useState(1);
  return (
    <FormProvider>
      <WWWContainer page={page}>
        <WWWForm page={page} setPage={setPage} />
      </WWWContainer>
    </FormProvider>
  );
};

export default Index;
