import React, { FC, FormEvent, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../styles/Theme";
import Select, { ActionMeta, MultiValue } from "react-select";
import { FormOptions, FormOptionsSustainability } from "../../@types";
import { Tooltip } from "@mui/material";
import { HelpQuestion } from "react-basicons";
import { target_labelSliderChange } from "../../helpers/common";
import CookieBanner from "../CookieBanner";
import { tryParseInt } from "../../helpers/tryParseInt";
import EnergyLabelSlider from "../slider";

interface FieldOption {
  value: string | number;
  label: string;
}

export interface Field {
  label: string;
  placeholder?: string;
  type: string;
  name: string;
  options?: FieldOption[];
  tooltip?: string;
}

interface Section {
  title: string;
  startIndex: number;
  endIndex: number;
}

interface Props {
  setIsFormActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface FormInputProps {
  initialFields: Field[];
  sectionFields: Section[];
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  setFormValues?: (value: FormOptions | FormOptionsSustainability) => void;
  formValues?: FormOptions | FormOptionsSustainability;
  inputChange?: (e: any, a?: any) => void;
  lockMeasure?: never[];
  excludeMeasure?: never[];
  title: string;
  options?: string;
}

export enum InputTypes {
  SELECT = "select",
  CHECKBOX = "checkbox",
  RADIO = "radio",
  TEXT = "text",
  SLIDER = "slider",

  NUMBER = "number",
  MULTISELECT = "multiselect",
}

const APIForm: FC<FormInputProps & Props> = ({
  initialFields,
  sectionFields,
  handleSubmit,
  formValues,
  setFormValues,
  inputChange,
  lockMeasure,
  excludeMeasure,
  setIsFormActive,
  title,
  options,
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setIsFormActive(true);
    let { name, value } = event.target;
    const [isInt, parsedValue] = tryParseInt(value);
    if (setFormValues) {
      setFormValues({
        ...formValues,
        [name]: isInt ? parsedValue : stringToBoolean(value),
      });
    }
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    handleSubmit(event);

    setIsFormActive(true);
  };

  const [showContent, setShowContent] = useState(false);

  const fieldsGreaterThanOne = sectionFields.length > 1;

  return (
    <Form onSubmit={onSubmit}>
      {sectionFields?.map((section, sectionIndex) => {
        const { title, startIndex, endIndex } = section;
        const sectionData = initialFields.slice(startIndex, endIndex + 1);

        return (
          <div key={sectionIndex}>
            {sectionIndex === 0 && (
              <>
                <SubTitle>{title}</SubTitle>
                <div key={sectionIndex} className="section-container">
                  {sectionData.map((field) => {
                    const { label, placeholder, type, name, options, tooltip } =
                      field;

                    const error = errors[name];

                    if (type === InputTypes.SELECT) {
                      return (
                        <div key={name} className="input-flex">
                          <div
                            className="tooltip-flex"
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                            }}
                          >
                            <label>{label}</label>
                            <Tooltip title={tooltip} placement="top" arrow>
                              <span>
                                <HelpQuestion size={15} />
                              </span>
                            </Tooltip>
                          </div>
                          <select
                            name={name}
                            value={formValues && formValues[name]}
                            onChange={handleChange}
                          >
                            <option value="">{placeholder}</option>
                            {options?.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </div>
                      );
                    }

                    if (type === InputTypes.MULTISELECT) {
                      return (
                        <div key={name} className="input-flex">
                          <div
                            className="tooltip-flex"
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                            }}
                          >
                            <label>{label}</label>
                            <Tooltip title={tooltip} placement="top" arrow>
                              <span>
                                <HelpQuestion size={15} />
                              </span>
                            </Tooltip>
                          </div>
                          <Select
                            options={options as never}
                            placeholder={placeholder}
                            name={name}
                            onChange={
                              inputChange as (
                                newValue: MultiValue<never>,
                                actionMeta: ActionMeta<never>,
                              ) => void
                            }
                            value={
                              name === "lock_measures"
                                ? lockMeasure
                                : excludeMeasure
                            }
                            isMulti
                          />

                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </div>
                      );
                    }

                    if (type === InputTypes.RADIO) {
                      return (
                        <div key={name} className="input-flex">
                          <div
                            className="tooltip-flex"
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                            }}
                          >
                            <label>{label}</label>
                            {tooltip && (
                              <Tooltip title={tooltip} placement="top" arrow>
                                <span>
                                  <HelpQuestion size={15} />
                                </span>
                              </Tooltip>
                            )}
                          </div>
                          {options?.map((option, index) => (
                            <div key={index} style={{ display: "flex" }}>
                              <input
                                type="radio"
                                name={name}
                                value={option.value}
                                checked={
                                  (formValues &&
                                    (formValues[name] as string)) ===
                                  option.value
                                }
                                onChange={handleChange}
                              />
                              <label>{option.label}</label>
                            </div>
                          ))}
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </div>
                      );
                    }
                    if (type === InputTypes.CHECKBOX) {
                      return (
                        <div key={name} className="input-flex">
                          <div style={{ display: "flex" }}>
                            <input
                              type="checkbox"
                              name={name}
                              onChange={(e) => {
                                setFormValues &&
                                  setFormValues({
                                    ...formValues,
                                    [name]: e.target.checked,
                                  });
                              }}
                            />
                            <label>{label}</label>
                          </div>

                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </div>
                      );
                    }
                    if (type === InputTypes.SLIDER && setFormValues) {
                      return (
                        <div key={name} className="input-flex">
                          <label>{label}</label>
                          <EnergyLabelSlider
                            inputName={name}
                            targetLabel={
                              formValues && formValues[name]
                                ? formValues[name]
                                : "A"
                            }
                            onSliderChange={(x) =>
                              target_labelSliderChange(x, name, setFormValues)
                            }
                          />
                        </div>
                      );
                    }

                    return (
                      <div key={name} className="input-flex">
                        <div
                          className="tooltip-flex"
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          <label>{label}</label>
                          {tooltip && (
                            <Tooltip title={tooltip} placement="top" arrow>
                              <span>
                                <HelpQuestion size={15} />
                              </span>
                            </Tooltip>
                          )}
                        </div>

                        <input
                          type={type}
                          name={name}
                          value={formValues && (formValues[name] as string)}
                          onChange={handleChange}
                          placeholder={placeholder}
                          required={
                            name === "post_code" ||
                            name === "house_number" ||
                            name === "postcode" ||
                            name === "housenumber" ||
                            name === "searchString"
                          }
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        );
      })}

      {title === "Verduurzamingsadvies genereren" && (
        <CookieBanner
          color={""}
          body={"Voor het wijzigen van de kosten, gebruik de API"}
        >
          <a
            href="https://docs.altum.ai/apis/sustainability-api"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "green",
              fontWeight: "500",
              textDecoration: "underline",
            }}
          >
            zie kostentabel
          </a>
        </CookieBanner>
      )}

      {sectionFields?.map((section, sectionIndex) => {
        const { title, startIndex, endIndex } = section;
        const sectionData = initialFields.slice(startIndex, endIndex + 1);

        return (
          <div key={sectionIndex}>
            {sectionIndex > 0 && (
              <>
                <SubTitle>{title}</SubTitle>
                <div key={sectionIndex} className="section-container">
                  {sectionData?.map((field) => {
                    const { label, placeholder, type, name, options, tooltip } =
                      field;

                    const error = errors[name];

                    if (type === InputTypes.SELECT) {
                      return (
                        <div key={name} className="input-flex">
                          <div
                            className="tooltip-flex"
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                            }}
                          >
                            <label>{label}</label>
                            {/* <Tooltip title={tooltip} placement="top" arrow>
                              <span>
                                <HelpQuestion size={15} />
                              </span>
                            </Tooltip> */}
                          </div>
                          <select
                            name={name}
                            value={formValues && (formValues[name] as string)}
                            onChange={handleChange}
                          >
                            <option value="">{placeholder}</option>
                            {options?.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </div>
                      );
                    }

                    if (type === InputTypes.MULTISELECT) {
                      return (
                        <div key={name} className="input-flex">
                          <div
                            className="tooltip-flex"
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                            }}
                          >
                            <label>{label}</label>
                            {/* <Tooltip title={tooltip} placement="top" arrow>
                              <span>
                                <HelpQuestion size={15} />
                              </span>
                            </Tooltip> */}
                          </div>
                          <Select
                            options={options as never}
                            placeholder={placeholder}
                            name={name}
                            onChange={
                              inputChange as (
                                newValue: MultiValue<never>,
                                actionMeta: ActionMeta<never>,
                              ) => void
                            }
                            value={
                              name === "lock_measures"
                                ? lockMeasure
                                : excludeMeasure
                            }
                            isMulti
                          />

                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </div>
                      );
                    }

                    if (type === InputTypes.RADIO) {
                      return (
                        <div key={name} className="input-flex">
                          <div
                            className="tooltip-flex"
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                            }}
                          >
                            <label>{label}</label>
                            {/* <Tooltip title={tooltip} placement="top" arrow>
                              <span>
                                <HelpQuestion size={15} />
                              </span>
                            </Tooltip> */}
                          </div>
                          {options?.map((option, index) => (
                            <div key={index} style={{ display: "flex" }}>
                              <input
                                type="radio"
                                name={name}
                                value={option.value}
                                checked={
                                  formValues &&
                                  String(formValues[name]) === option.value
                                }
                                onChange={handleChange}
                              />
                              <label>{option.label}</label>
                            </div>
                          ))}
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </div>
                      );
                    }

                    return (
                      <div key={name} className="input-flex">
                        <div
                          className="tooltip-flex"
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          <label>{label}</label>
                          {/* <Tooltip title={tooltip} placement="top" arrow>
                            <span>
                              <HelpQuestion size={15} />
                            </span>
                          </Tooltip> */}
                        </div>

                        <input
                          type={type}
                          name={name}
                          value={formValues && (formValues[name] as string)}
                          onChange={handleChange}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        );
      })}
      <ButtonStyle type="submit">Versturen</ButtonStyle>
    </Form>
  );
};

function stringToBoolean(value: string) {
  if (typeof value !== "string") {
    throw new Error("Expected a string argument");
  }

  if (value.toLowerCase() === "true") return true;
  if (value.toLowerCase() === "false") return false;

  return value;
}
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .section-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
  .input-flex {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
    min-width: 100%;
  }

  input {
    font-size: 14px;
    padding: 10px;
    border: 1px solid ${Colors.main.green};
    border-radius: 5px;
    color: ${Colors.main.darkgrey};
    margin-right: 10px;
  }

  select {
    font-size: 14px;
    padding: 10px;
    border: 1px solid ${Colors.main.green};
    color: ${Colors.main.darkgrey};
    border-radius: 5px;
  }

  label {
    font-size: 16px;
    font-weight: 500;
    color: black;
    opacity: 0.8;
    display: block;
  }
  label::first-letter {
    text-transform: capitalize;
  }
  .radio-flex {
    display: flex;
    gap: 10px;
    align-items: center;
  }
`;

const SubTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  color: green;
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

const ErrorMessage = styled.div`
  color: red;
`;

const ButtonStyle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #1e9d66;
  width: 200px;
  margin-top: 3rem;
  padding: 1rem;
  border: none;
  border-radius: 4px;
  font-size: 20px;
  :hover {
    transform: scale(1.1);
  }
  a {
    color: #1e9d66;
    background: white;
    padding: 1rem;
    border-radius: 4px;
  }
`;

export default APIForm;
