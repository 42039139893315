import React from "react";
import ReturnInputField from "../../../../../components/ReturnInputField";
import { useFormContext } from "../../components/FormContext";
import { sanitaryInfoFields } from "../data";

const SanitaryInfo = () => {
  const { formValues, setFormValues } = useFormContext();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      <h2>Sanitary Information</h2>
      {sanitaryInfoFields.map(({ name, ...field }) => (
        <ReturnInputField
          key={name}
          {...field}
          name={name}
          value={name && formValues[name] ? formValues[name] : ""}
          onChange={(event) => {
            if ("target" in event) {
              handleInputChange(event as React.ChangeEvent<HTMLInputElement>);
            }
          }}
        />
      ))}
    </div>
  );
};

export default SanitaryInfo;
