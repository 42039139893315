import React, { useEffect, useMemo, useCallback } from "react";
import Select, { MultiValue, ActionMeta } from "react-select";
import { useFormContext } from "../pages/Api/usage-pages/components/FormContext";
import {
  getBackgroundColor,
  target_labelSliderChange,
} from "../helpers/common";
import Input from "./TextInput";
import EnergyLabelSlider from "./slider";
import RadioUsageFormComponent from "../pages/Api/usage-pages/components/RadioUsageFormComponent";

export enum InputTypes {
  SELECT = "select",
  CHECKBOX = "checkbox",
  RADIO = "radio",
  TEXT = "text",
  SLIDER = "slider",
  NUMBER = "number",
  MULTISELECT = "multiselect",
}

type Option = {
  label: string;
  value: string | number;
};

type Props = {
  type?: string;
  label?: string;
  placeholder?: string;
  name?: string;
  stack?: boolean;
  value?: string | Option[] | boolean | number;
  options?: Option[];
  onChange?: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | MultiValue<Option>
      | Option,
    actionMeta?: ActionMeta<Option>,
  ) => void;
};

const ReturnInputField: React.FC<Props> = ({
  type,
  label,
  placeholder,
  name,
  value,
  stack,
  options,
  onChange,
}) => {
  const { formValues, setFormValues, errors } = useFormContext();
  const sliderCircle = document.querySelector(
    `#${name} > div:last-child > div:last-child > div`,
  ) as HTMLElement | null;
  const track1 = document.querySelector(
    `#${name} > div:first-child > div`,
  ) as HTMLElement | null;
  useEffect(() => {
    if (type === InputTypes.SLIDER && name) {
      const label = formValues[name] || "A";
      if (sliderCircle) {
        sliderCircle.classList.add("form-slider");
        sliderCircle.style.backgroundColor =
          getBackgroundColor(label) ?? "rgba(0,166,82,255)";
        sliderCircle.textContent = label;
      }

      if (track1 && track1.classList) {
        track1.classList.add(`${name}-track`);
        track1.style.backgroundColor =
          getBackgroundColor(label) ?? "rgba(0,166,82,255)";
      }
    }
  }, [formValues, name, sliderCircle, track1, type]);

  const handleChange = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
        | MultiValue<Option>
        | Option,
      actionMeta?: ActionMeta<Option>,
    ) => {
      if (onChange) {
        onChange(event, actionMeta);
      }
    },
    [onChange],
  );

  const handleSlider = useCallback(
    (x: number) => {
      target_labelSliderChange(x, name, setFormValues);
    },
    [name, setFormValues],
  );

  const renderInput = useMemo(() => {
    switch (type) {
      case InputTypes.SELECT:
        return (
          options && (
            <select
              name={name}
              value={formValues && name && formValues[name]}
              onChange={handleChange}
              className="focus:border-0 focus:ring-0 focus:outline-primary border-gray-light w-full rounded-md"
            >
              <option>{placeholder}</option>
              {options?.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          )
        );
      case InputTypes.MULTISELECT:
        return (
          <Select
            options={options}
            placeholder={placeholder}
            name={name}
            value={value as Option[]}
            isMulti
            onChange={(
              newValue: MultiValue<Option>,
              actionMeta: ActionMeta<Option>,
            ) => handleChange(newValue, actionMeta)}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border: state.isFocused
                  ? "2px solid #27AE60"
                  : "1px solid gray",
                outlineColor: state.isFocused ? "#27AE60" : "gray",
                boxShadow: state.isFocused ? "" : "",
              }),
            }}
          />
        );
      case InputTypes.SLIDER:
        return (
          name && (
            <EnergyLabelSlider
              inputName={name}
              targetLabel={formValues[name] || "A"}
              onSliderChange={handleSlider}
            />
          )
        );
      case InputTypes.RADIO:
        return (
          <RadioUsageFormComponent
            options={options}
            value={formValues && name && formValues[name]}
            stack={stack}
            onChange={(value) =>
              name &&
              setFormValues({
                ...formValues,
                [name]: value,
              })
            }
          />
        );
      case InputTypes.CHECKBOX:
        return (
          <Input
            type="checkbox"
            size="md"
            name={name}
            message={errors[name]}
            checked={formValues && name && formValues[name]}
            onChange={
              handleChange as (e: React.ChangeEvent<HTMLInputElement>) => void
            }
          />
        );
      default:
        return (
          <Input
            type={type}
            size="md"
            name={name}
            message={errors[name]}
            value={formValues && name && formValues[name]}
            placeholder={placeholder}
            onChange={
              handleChange as (e: React.ChangeEvent<HTMLInputElement>) => void
            }
          />
        );
    }
  }, [
    type,
    options,
    name,
    handleChange,
    placeholder,
    value,
    formValues,
    handleSlider,
    stack,
    errors,
    setFormValues,
  ]);

  return (
    <div>
      <label className="text-gray-light mb-2">
        {type === InputTypes.CHECKBOX ? (
          <>
            {renderInput}
            <span className="ml-2">{label}</span>
          </>
        ) : (
          label
        )}
      </label>
      {type !== InputTypes.CHECKBOX && renderInput}
    </div>
  );
};

export default ReturnInputField;
