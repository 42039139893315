import { InputProps } from "../../../../@types";

export const basicInfoFields: InputProps[] = [
  {
    label: "House Type*",
    name: "house_type",
    type: "select",
    placeholder: "Select house type",
    tooltip: "Select the type of the house",
    options: [
      { label: "Single-family home", value: "Eengezinswoning" },
      { label: "Multi-family home", value: "Meergezinswoning" },
      { label: "Duplex", value: "Duplexwoning" },
    ],
    required: true,
  },
  {
    label: "Living Area (m²)",
    name: "living_area_m2",
    type: "number",
    placeholder: "100",
    tooltip: "Enter the total living area of the property in square meters",
  },
  {
    label: "Surface Area of Other Spaces (m²)",
    name: "surface_area_other_spaces",
    type: "number",
    placeholder: "20",
    tooltip: "Enter the surface area of other spaces in square meters",
  },
  {
    label: "Attic Space Area (m²)",
    name: "attic_space_area",
    type: "number",
    placeholder: "15",
    tooltip: "Enter the attic space area in square meters",
  },

  {
    label: "Building Year*",
    name: "building_year",
    type: "number",
    placeholder: "2000",
    tooltip: "Enter the year the building was constructed",
    required: true,
  },
];

export const energyInfoFields: InputProps[] = [
  {
    label: "Energy Label Available",
    name: "energy_label_available",
    type: "radio",
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
    tooltip: "Indicate if an energy label is available for the property",
  },
  {
    label: "Energy Label",
    name: "energy_label",
    type: "slider",
    tooltip: "Select the energy label of the property",
  },
  {
    label: "Energy Performance Payment",
    name: "energy_performance_payment",
    type: "radio",
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
    tooltip: "Indicate if there's an energy performance payment",
  },
];

export const kitchenInfoFields: InputProps[] = [
  {
    label: "Countertop with Base Cabinets",
    name: "countertop_with_base_cabinets",
    type: "radio",
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
    tooltip: "Indicate if the kitchen has a countertop with base cabinets",
  },
  {
    label: "Countertop Length (m)",
    name: "countertop_lenght",
    type: "number",
    placeholder: "2",
    tooltip: "Enter the length of the countertop in meters",
  },
  {
    label: "Investment for Kitchen Extra Quality",
    name: "investment_for_kitchen_extra_quality",
    type: "number",
    placeholder: "5000",
    tooltip: "Enter the investment amount for extra kitchen quality",
  },
];

export const sanitaryInfoFields: InputProps[] = [
  {
    label: "Toilet Installed in Bathroom or Shower",
    name: "toilet_installed_in_bathroom_or_shower",
    type: "radio",
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
    tooltip: "Indicate if there's a toilet installed in the bathroom or shower",
  },
  {
    label: "Sanitary Facilities",
    name: "list_sanitary_facilities",
    type: "multiselect",
    options: [
      { label: "Toilet", value: "toilet" },
      { label: "Sink", value: "sink" },
      { label: "Bath", value: "bath" },
      { label: "Bath Shower", value: "bath shower" },
      { label: "Shower", value: "shower" },
    ],
    tooltip: "Select all available sanitary facilities",
  },
  {
    label: "Investment for Bath Extra Quality",
    name: "investment_for_bath_extra_quality",
    type: "number",
    placeholder: "3000",
    tooltip: "Enter the investment amount for extra bath quality",
  },
  {
    label: "Investment for Shower Extra Quality",
    name: "investment_for_shower_extra_quality",
    type: "number",
    placeholder: "2000",
    tooltip: "Enter the investment amount for extra shower quality",
  },
];

export const additionalInfoFields: InputProps[] = [
  {
    label: "Facilities for Disabled",
    name: "facilities_for_disabled",
    type: "radio",
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
    tooltip: "Indicate if there are facilities for disabled persons",
  },
  {
    label: "Costs Spent for Facilities for Disabled",
    name: "costs_spent_for_facilities_for_disabled",
    type: "number",
    placeholder: "10000",
    tooltip: "Enter the costs spent on facilities for disabled persons",
  },
  {
    label: "Private Outdoor Space (m²)",
    name: "private_outdoor_space_m2",
    type: "number",
    placeholder: "20",
    tooltip: "Enter the size of the private outdoor space in square meters",
  },
  {
    label: "Carport",
    name: "carport",
    type: "radio",
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
    tooltip: "Indicate if there's a carport",
  },
  {
    label: "Extra Quality Above 100m²",
    name: "extra_quality_above_100m2",
    type: "radio",
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
    tooltip: "Indicate if there's extra quality for area above 100m²",
  },
  {
    label: "Renovation Expenses",
    name: "renovation_expenses",
    type: "number",
    placeholder: "50000",
    tooltip: "Enter the total renovation expenses",
  },
  {
    label: "Monument",
    name: "monument",
    type: "radio",
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
    tooltip: "Indicate if the property is a monument",
  },
];

export const progress = [
  "Basic Information",
  "Energy Information",
  "Kitchen Information",
  "Sanitary Information",
  "Additional Information",
  "Result",
];
