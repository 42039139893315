import { useState, ReactNode, useEffect } from "react";
import DashboardContainer from "../../../../Dashboard/components/Container";
import Header from "../../../../Dashboard/components/Header";
import { useFormContext } from "../../components/FormContext";
import { Ecovalue } from "../../../../../assets/images/api/APIimages";
import ImageContainer from "../../components/ImageContainer";
import JsonToggle from "../../components/JsonToggle";
import { useAppSelector } from "../../../../../redux/hooks";
import ImageSkeleton from "../../components/ImageSkeleton";
import JsonViewer from "../../components/JsonViewer";
import ProgressSection from "../../components/ProgressSection";
import { ExplorOtherApis } from "../../sustainability/components/Result";
import { stringToBoolean } from "../../../../../helpers/common";

const progress = [
  "Basis informatie",
  "Energie informatie",
  "Keuze van maatregelen",
  "Sanitair",
  "Aanvullende informatie",
  "Resultaat",
];

const WWWContainer: React.FC<{ children: ReactNode; page: number }> = ({
  children,
  page,
}) => {
  const { formValues, buildingPhoto, fetchAddressData } = useFormContext();
  const { result } = useAppSelector((state) => state.wws);

  const [toggle, setToggle] = useState(false);
  const updatedData: Record<string, any> = {};

  if (formValues.post_code) {
    updatedData.post_code = formValues.post_code
      .split(" ")
      .join("")
      .toUpperCase();
  }

  Object.entries(formValues).forEach(([key, value]) => {
    if (value !== undefined) {
      updatedData[key] = stringToBoolean(value);
    }
  });

  useEffect(() => {
    if (Object.keys(result).length > 0 && buildingPhoto.length === 0) {
      fetchAddressData();
    }
  }, [result, fetchAddressData, buildingPhoto.length]);

  return (
    <DashboardContainer pageTitle="WWS-punten berekenen - Altum AI">
      <Header
        title="WWS-punten berekenen"
        subtitle="Bereken de WWS-punten voor een woning op basis van de kenmerken en faciliteiten van de woning."
      />
      <JsonToggle toggle={toggle} setToggle={setToggle} />
      <div className="flex flex-col gap-16 md:gap-8 max-w-[1100px] pt-4 md:pt-4 relative">
        {/* {page > 1 && buildingPhoto && !toggle && (
          <ImageContainer image={buildingPhoto} address={houseAddress} />
        )}
        {((page > 1 && !buildingPhoto && !toggle) || loading) && (
          <ImageSkeleton />
        )} */}

        <div className="flex flex-col-reverse gap-1 lg:gap-8 lg:flex-row">
          {toggle ? (
            <JsonViewer
              showFormValues={page < 6}
              formValues={updatedData}
              result={result}
            />
          ) : (
            <div className="flex flex-col w-full p-4 shadow-[0px_0px_4px_0px_#00000026] rounded-2xl max-w-[800px] md:max-h-[830px] p-3 lg:p-8 gap-8">
              {children}
            </div>
          )}

          <ProgressSection page={page} image={Ecovalue} progress={progress} />
        </div>
        {page === 6 && <ExplorOtherApis />}
      </div>
    </DashboardContainer>
  );
};

export default WWWContainer;
