import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { Redirect, useHistory } from "react-router-dom";
import RedoButton from "../../components/RedoButtons";
import {
  clearWWSResults,
  modifyWWSQueries,
} from "../../../../../redux/actions/wwsActions";
import WWWResultSummary from "../components/WWWResultSummary";
import WWWResultDetails from "../components/WWWResultDetails";

const WWWResult = () => {
  const { result, loading } = useAppSelector((state) => state.wws);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const clearResults = () => {
    dispatch(clearWWSResults());
    history.push("/wws");
  };

  const modifyResults = () => {
    dispatch(modifyWWSQueries());
    history.push("/wws");
  };

  if (Object.keys(result).length === 0 && !loading) {
    return <Redirect to={{ pathname: "/wws" }} />;
  }

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <WWWResultSummary result={result} />
          <WWWResultDetails result={result} />
          <RedoButton modify={modifyResults} clear={clearResults} />
        </>
      )}
    </>
  );
};

export default WWWResult;
