import { FC, useCallback, useState } from "react";
import IdentifyProperty from "../components/IdentifyProperty";
import IsMeasureImplemented from "../components/IsMeasureImplemented";
import MeasureImplemented from "../components/MeasureImplemented";
import Preferences from "../components/Preferences";
import PropertyStatus from "../components/PropertyStatus";
import WhatsYourGoal from "../components/WhatsYourGoal";
import { postSustainability } from "../../../../../redux/actions/sustainabilityActions";
import { FormOptions, FormOptionsSustainability } from "../../../../../@types";
import { stringToBoolean } from "../../../../../helpers/common";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { useFormContext } from "../../components/FormContext";
import PageNav from "../../components/PageNav";
import { Redirect } from "react-router-dom";

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};
const SustainabilityForm: FC<Props> = ({ page, setPage }) => {
  const { formValues, validate, fetchAddressData } = useFormContext();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.sustainability);
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);
  const [isMeasure, setIsMeasure] = useState<string | number>("no");

  const validatePostCode = useCallback(() => {
    if (!formValues) return false;
    const postCodeErrors = validate(
      {
        post_code: formValues.post_code,
        house_number: formValues.house_number,
      },
      {
        post_code: {
          minLength: 6,
          maxLength: 6,
          regex: /^[0-9]{4}[A-Za-z]{2}$/i,
          required: true,
        },
        house_number: {
          minLength: 1,
          maxLength: 6,
          required: true,
        },
      },
    );

    return (
      (!postCodeErrors.post_code || postCodeErrors.post_code?.length === 0) &&
      (!postCodeErrors.house_number ||
        postCodeErrors.house_number?.length === 0)
    );
  }, [formValues, validate]);

  const handleFormSubmit = useCallback(() => {
    const updatedData: FormOptions | FormOptionsSustainability = {
      post_code: formValues.post_code?.split(" ").join("").toUpperCase() || "",
      fetch_definitive_label: 1,
      search_criteria: 2,
    };

    Object.entries(formValues).forEach(([key, value]) => {
      if (value !== undefined) {
        updatedData[key] = stringToBoolean(value);
      }
    });
    const formData = { data: updatedData };
    apiKey && dispatch(postSustainability({ formData, apiKey }));
  }, [apiKey, dispatch, formValues]);

  const next = useCallback(() => {
    if (page === 1 && !validatePostCode()) return;
    if (page === 1 && validatePostCode()) fetchAddressData();
    if (page === 3) handleFormSubmit();

    setPage((prevPage) =>
      isMeasure === "yes" && prevPage === 2
        ? prevPage + 0.5
        : Math.floor(prevPage) + 1,
    );
  }, [
    page,
    validatePostCode,
    fetchAddressData,
    handleFormSubmit,
    setPage,
    isMeasure,
  ]);

  if (loading) {
    return (
      <Redirect
        to={{
          pathname: "/sustainability-result",
        }}
      />
    );
  }
  return (
    <>
      {page === 1 && (
        <>
          <IdentifyProperty />
          <WhatsYourGoal />
        </>
      )}
      {page === 2 && (
        <>
          <PropertyStatus />
          <IsMeasureImplemented
            isMeasure={isMeasure}
            setIsMeasure={setIsMeasure}
          />
        </>
      )}
      {page > 2 && page < 3 && isMeasure === "yes" && <MeasureImplemented />}
      {page === 3 && <Preferences />}
      {page < 4 && (
        <PageNav
          page={page}
          disabled={Object.keys(formValues).length === 0}
          next={next}
          prev={() => setPage((p) => Math.max(Math.round(p - 1), 1))}
        />
      )}
    </>
  );
};

export default SustainabilityForm;
